<template>
  <div class="vehicle-record">
    <div class="record-centent">
      <div class="form-area table-search">
        <el-form
          class="demo-form-inline"
          ref="form"
          :model="form"
          label-position="left"
          label-width="82px"
          :inline="true"
        >
          <el-form-item label="开始时间：" prop="beginTime">
            <el-date-picker
              v-model="form.beginTime"
              type="date"
              placeholder="选择开始时间"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间：" prop="endTime">
            <el-date-picker
              v-model="form.endTime"
              type="date"
              placeholder="选择结束时间"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="选择线路：">
            <el-select
              clearable
              v-model="form.companyIds"
              multiple
              collapse-tags
              filterable
              placeholder="请选择线路"
            >
              <el-option
                v-for="item in busLineList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择司机：">
            <el-select
              clearable
              v-model="form.realityDriverList"
              :filter-method="vehicleMethodFun"
              multiple
              collapse-tags
              filterable
              placeholder="请选择司机"
            >
              <el-option
                v-for="item in driversOptions"
                :key="item.credentialsNo"
                :label="item.driverName"
                :value="item.credentialsNo"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择车辆：">
            <el-select
              clearable
              :filter-method="filterMethodFun"
              v-model="form.vehicleNoList"
              multiple
              collapse-tags
              filterable
              placeholder="请选择车辆"
            >
              <el-option
                v-for="item in vehicleOptions"
                :key="item.vehicleNo"
                :label="item.cph"
                :value="item.vehicleNo"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="驾驶状态：">
            <el-select
              clearable
              v-model="form.drivingState"
              placeholder="请选择"
            >
              <el-option
                v-for="item in drivingStateList"
                :label="item.stateValue"
                :value="item.stateCode"
                :key="item.stateCode"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="alarm-btn">
            <el-button size="small" type="primary" @click="search"
              >查询</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click="openDiaLog('dialogVisibletrip', '补录行程')"
              >补录行程</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click="openDiaLog('dialogVisibleSuretrip', '确认行驶记录')"
              >确认行程</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click="openDiaLog('dialogVisible', '生成结算单')"
              >生成结算单</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        height="calc(100vh - 260px)"
        @selection-change="handleSelectionChange"
        stripe
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="workDay" label="日期"></el-table-column>
        <el-table-column prop="companyName" label="线路"></el-table-column>
        <el-table-column prop="cph" label="车辆"></el-table-column>
        <el-table-column
          prop="realityDriverName"
          label="司机"
        ></el-table-column>
        <el-table-column prop="moveType" label="方向">
          <template slot-scope="scope">
            {{ scope.row.moveType === 1 ? "上行" : "下行" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="planLeaveTime"
          label="计划发车"
        ></el-table-column>
        <el-table-column prop="leaveTime" label="实际发车"></el-table-column>
        <el-table-column
          prop="planArriveTime"
          label="计划到达"
        ></el-table-column>
        <el-table-column prop="arriveTime" label="实际到达"></el-table-column>
        <el-table-column prop="drivingState" label="行驶状态">
          <template slot-scope="scope">
            {{
              scope.row.drivingState === 0
                ? "未确认"
                : scope.row.drivingState === 1
                ? "已完成"
                : "未完成"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="runMile" label="里程数"></el-table-column>
        <el-table-column prop="oilConsumption" label="油耗"></el-table-column>
        <el-table-column prop="times" label="趟次" width="50"></el-table-column>
        <el-table-column prop="natureState" label="变更说明">
          <template slot-scope="scope">{{
            scope.row.natureState | filterNatureState
          }}</template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="form.currentPage"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <!-- 生成结算单弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="35%"
    >
      <el-form
        ref="statementsForm"
        :model="createStatementsFrom"
        label-position="left"
        label-width="120px"
        class="demo-form dialog-form"
        :rules="statementRules"
      >
        <el-form-item label="线路:" prop="companyId">
          <el-select
            v-model="createStatementsFrom.companyId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in busLineList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="日期:" prop="settlementTime">
          <el-date-picker
            v-model="createStatementsFrom.settlementTime"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :picker-options="datePickerOptions"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="buttonGroup text-right tabButtonGroup">
        <el-button type="default" size="small" @click="cancleStatementsFromDia"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="submitStatementsFrom"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 补录行程弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisibletrip"
      v-if="dialogVisibletrip"
      width="45%"
    >
      <addRecordTripDialog
        :busLineList="busLineList"
        :drivingStateList="drivingStateOptions"
        @closeDia="val => (dialogVisibletrip = val)"
        @submitTripDiaFrom="submitTripRecord"
      ></addRecordTripDialog>
    </el-dialog>
    <!-- 确认行程 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisibleSuretrip"
      v-if="dialogVisibleSuretrip"
      width="45%"
    >
      <div class="form search-area" style="padding:0 16px;">
        <el-form
          :model="sureform"
          :rules="rules"
          status-icon
          label-position="left"
          ref="sureform"
          label-width="90px"
          class="demo-form form-search"
        >
          <el-form-item label="是否完成:" prop="drivingState">
            <el-radio-group v-model="sureform.drivingState">
              <el-radio
                v-for="item in drivingStateOptions"
                :key="item.value"
                :label="item.value"
                >{{ item.content }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item
            label="是否计趟:"
            prop="isCountTimes"
            v-if="sureform.drivingState == 1"
          >
            <el-radio-group v-model="sureform.isCountTimes">
              <el-radio
                v-for="item in countTimesOptions"
                :key="item.value"
                :label="item.value"
                >{{ item.content }}</el-radio
              >
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="司机:">
            <el-select
              ref="select_element"
              v-model="sureform.realityDriver"
              clearable
              filterable
              :filter-method="vehicleMethodFun"
              placeholder="请输入司机"
            >
              <el-option
                v-for="item in driversOptions"
                :key="item.credentialsNo"
                :label="item.driverName"
                :value="item.credentialsNo"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="里程数:"
            prop="runMile"
            v-if="
              sureform.recordIds.length === 1 && sureform.drivingState === 1
            "
          >
            <el-input-number
              :min="0"
              size="small"
              v-model="sureform.runMile"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="备注:" prop="remark">
            <el-input
              style="margin-top: 10px;width:250px;"
              type="textarea"
              :rows="3"
              v-model="sureform.remark"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="buttonGroup text-right">
        <el-button size="small" type="default" @click="cancleSchedule"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="confirmSchedule"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDriverRecordList,
  queryAllBusLineList,
  getDriverByline,
  getBusByline,
  addDriverRecord,
  createSettlements,
  confirmDrivingRecord
} from "@/api/lib/bus-api.js";
import {
  getStartDate,
  getCurentDate,
  checkTimeLimit,
  checkTimeLimitDay,
  checkLimitDate,
  formatDate,
  formatDay,
  getCurent
} from "@/common/utils/index";
import addRecordTripDialog from "../dialogComponents/addRecordTripDialog";
export default {
  components: {
    addRecordTripDialog
  },
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        system: "0", //对应系统
        beginTime: new Date(getStartDate()), // 开始时间
        endTime: new Date(getCurentDate()), // 结束时间
        companyIds: [], //选择线路
        realityDriverList: [], //选择司机
        vehicleNoList: [], //选择车辆
        drivingState: "" //驾驶状态
      },
      total: 0, //总条数
      tableData: [], //车辆行驶记录表格数据
      busLineList: [], //线路列表
      driversOptions: [], //驾驶员列表
      vehicleOptions: [], //车辆列表
      dialogVisible: false, //生成结算单弹窗
      dialogVisibletrip: false, //补录行程
      dialogVisibleSuretrip: false, //确认行程
      multipleSelection: [], //表格多选数据
      createStatementsFrom: {
        companyId: "",
        settlementTime: ""
      },
      drivingStateList: [
        { stateValue: "未确认", stateCode: 0 },
        { stateValue: "已完成", stateCode: 1 },
        { stateValue: "未完成", stateCode: 2 }
      ],
      drivingStateOptions: [
        { content: "完成", value: 1 },
        { content: "未完成", value: 2 }
      ],
      sureform: {
        recordIds: [],
        remark: null,
        drivingState: "",
        realityDriver: null,
        runMile: null
      },
      // countTimesOptions: [
      //   { content: "计趟次", value: true },
      //   { content: "不计趟次", value: false }
      // ],
      rules: {
        recordIds: [{ required: true, message: "不能为空", trigger: "change" }],
        runMile: [{ required: true, message: "不能为空", trigger: "change" }],
        drivingState: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        realityDriver: [
          { required: true, message: "不能为空", trigger: "change" }
        ]
        // isCountTimes: [
        //   { required: true, message: "不能为空", trigger: "change" }
        // ]
      },
      statementRules: {
        companyId: [
          { required: true, message: "线路ID不能为空", trigger: "blur" }
        ],
        settlementTime: [
          { required: true, message: "请选择日期", trigger: "blur" }
        ]
      },
      pickerOptions: {
        disabledDate(time) {
          return checkLimitDate(time);
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3400 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      datePickerOptions: {
        disabledDate(time) {
          return (
            time >
            new Date(
              new Date(`${formatDay(new Date())} 00:00:00`).getTime() +
                1000 * 60 * 60 * 24 * -1
            )
          );
        }
      }
    };
  },
  filters: {
    filterNatureState(item) {
      if (item == 3) {
        return "加油";
      } else if (item == 2) {
        return "交班";
      } else if (item == 1) {
        return "排班";
      } else if (item == 4) {
        return "加气";
      } else if (item == 5) {
        return "充电";
      } else if (item == 6) {
        return "维修";
      } else if (item == 7) {
        return "包车";
      } else if (item == 128) {
        return "其他";
      } else if (item == 8) {
        return "恢复运营";
      } else {
        return "正常";
      }
    }
  },
  created() {
    this.queryAllBusLine();
  },
  methods: {
    //模糊查询司机
    vehicleMethodFun(text) {
      getDriverByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.driversOptions = res.data;
        }
        // else if(res.code===1100){
        //   this.$message.warning(res.msg)
        // }
      });
    },
    // 车辆模糊查询
    filterMethodFun(text) {
      getBusByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.vehicleOptions = res.data;
        }
      });
    },
    //确认行程
    confirmSchedule() {
      const data = { ...this.sureform };
      if (
        this.sureform.recordIds.length !== 1 ||
        this.sureform.drivingState === 2
      ) {
        delete data.runMile;
      }
      if (!this.sureform.realityDriver) {
        delete data.realityDriver;
      }
      this.$refs.sureform.validate(valid => {
        if (valid) {
          confirmDrivingRecord(data)
            .then(res => {
              this.$message.success("操作成功");
              this.dialogVisibleSuretrip = false;
              this.closeTripDialog();
              this.getDriverRecordData();
            })
            .catch(error => {});
        }
      });
    },
    cancleSchedule() {
      this.dialogVisibleSuretrip = false;
      this.closeTripDialog();
    },
    closeTripDialog() {
      this.sureform.remark = null;
      this.sureform.drivingState = null;
      this.sureform.realityDriver = null;
      this.sureform.runMile = null;
      this.driversOptions = [];
    },
    //补录行程
    submitTripRecord(val) {
      addDriverRecord(val).then(res => {
        if (res.code === 1000) {
          this.$message.success("操作成功");
          this.dialogVisibletrip = false;
          this.getDriverRecordData();
        }
      });
    },
    // 确定提交生成结算单
    submitStatementsFrom() {
      this.$refs.statementsForm.validate(valid => {
        if (valid) {
          createSettlements(this.createStatementsFrom).then(res => {
            if (res.code === 1000) {
              this.$message.success("生成结算单成功");
            } else if (res.code === 1100) {
              this.$message.warning(res.msg);
            }
          });
          this.dialogVisible = false;
        }
      });
    },
    cancleStatementsFromDia() {
      this.dialogVisible = false;
      this.createStatementsFrom = {
        settlementTime: "",
        companyId: ""
      };
    },
    //获取所有线路
    queryAllBusLine() {
      queryAllBusLineList().then(res => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.$message.warning("还没有线路信息，请先添加线路信息");
          } else {
            this.busLineList = res.data;
          }
        }
      });
    },
    // 获取警报信息列表
    getDriverRecordData() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = { ...this.form };
          data.beginTime = formatDate(this.form.beginTime).split(" ")[0];
          data.endTime = formatDate(this.form.endTime).split(" ")[0];
          getDriverRecordList(data).then(res => {
            if (res.code === 1000) {
              if (res.data.list.length > 0) {
                res.data.list.map(item => {
                  if (item.workDay) item.workDay = item.workDay.split(" ")[0];
                  if (item.planLeaveTime)
                    item.planLeaveTime = item.planLeaveTime
                      .split(" ")[1]
                      .slice(0, -3);
                  if (item.leaveTime)
                    item.leaveTime = item.leaveTime.split(" ")[1].slice(0, -3);
                  if (item.planArriveTime)
                    item.planArriveTime = item.planArriveTime
                      .split(" ")[1]
                      .slice(0, -3);
                  if (item.arriveTime)
                    item.arriveTime = item.arriveTime
                      .split(" ")[1]
                      .slice(0, -3);
                });
              }
              this.tableData = res.data.list || [];
              this.total = res.data.total;
            } else {
              this.tableData = [];
              this.total = 0;
            }
          });
        }
      });
    },
    search() {
      this.form.currentPage = 1;
      this.getDriverRecordData();
    },
    //显示弹窗
    openDiaLog(atrr, title) {
      if (
        atrr === "dialogVisibleSuretrip" &&
        this.multipleSelection.length < 1
      ) {
        this.$message.warning("请选择需要确认的记录");
        return;
      }
      this[atrr] = true;
      this.title = title;
    },
    // 当前页改变
    handleCurrentChange(page) {
      this.form.currentPage = page;
      this.getDriverRecordData();
    },
    // 每页显示条数改变
    handleSizeChange(size) {
      this.form.currentPage = 1;
      this.form.pageSize = size;
      this.getDriverRecordData();
    },
    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.sureform.recordIds = val.map(ele => ele.id);
    }
  }
};
</script>
<style lang="scss" scoped>
.alarm-btn {
  width: 400px;
}
</style>
