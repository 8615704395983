var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vehicle-record" },
    [
      _c(
        "div",
        { staticClass: "record-centent" },
        [
          _c(
            "div",
            { staticClass: "form-area table-search" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-form-inline",
                  attrs: {
                    model: _vm.form,
                    "label-position": "left",
                    "label-width": "82px",
                    inline: true
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开始时间：", prop: "beginTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "选择开始时间",
                          clearable: false,
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.form.beginTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "beginTime", $$v)
                          },
                          expression: "form.beginTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "结束时间：", prop: "endTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "选择结束时间",
                          clearable: false,
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.form.endTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "endTime", $$v)
                          },
                          expression: "form.endTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择线路：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            multiple: "",
                            "collapse-tags": "",
                            filterable: "",
                            placeholder: "请选择线路"
                          },
                          model: {
                            value: _vm.form.companyIds,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "companyIds", $$v)
                            },
                            expression: "form.companyIds"
                          }
                        },
                        _vm._l(_vm.busLineList, function(item) {
                          return _c("el-option", {
                            key: item.companyId,
                            attrs: {
                              label: item.companyName,
                              value: item.companyId
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择司机：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            "filter-method": _vm.vehicleMethodFun,
                            multiple: "",
                            "collapse-tags": "",
                            filterable: "",
                            placeholder: "请选择司机"
                          },
                          model: {
                            value: _vm.form.realityDriverList,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "realityDriverList", $$v)
                            },
                            expression: "form.realityDriverList"
                          }
                        },
                        _vm._l(_vm.driversOptions, function(item) {
                          return _c("el-option", {
                            key: item.credentialsNo,
                            attrs: {
                              label: item.driverName,
                              value: item.credentialsNo
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择车辆：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            "filter-method": _vm.filterMethodFun,
                            multiple: "",
                            "collapse-tags": "",
                            filterable: "",
                            placeholder: "请选择车辆"
                          },
                          model: {
                            value: _vm.form.vehicleNoList,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "vehicleNoList", $$v)
                            },
                            expression: "form.vehicleNoList"
                          }
                        },
                        _vm._l(_vm.vehicleOptions, function(item) {
                          return _c("el-option", {
                            key: item.vehicleNo,
                            attrs: { label: item.cph, value: item.vehicleNo }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "驾驶状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.form.drivingState,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "drivingState", $$v)
                            },
                            expression: "form.drivingState"
                          }
                        },
                        _vm._l(_vm.drivingStateList, function(item) {
                          return _c("el-option", {
                            key: item.stateCode,
                            attrs: {
                              label: item.stateValue,
                              value: item.stateCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "alarm-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.search }
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.openDiaLog(
                                "dialogVisibletrip",
                                "补录行程"
                              )
                            }
                          }
                        },
                        [_vm._v("补录行程")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.openDiaLog(
                                "dialogVisibleSuretrip",
                                "确认行驶记录"
                              )
                            }
                          }
                        },
                        [_vm._v("确认行程")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.openDiaLog(
                                "dialogVisible",
                                "生成结算单"
                              )
                            }
                          }
                        },
                        [_vm._v("生成结算单")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData,
                height: "calc(100vh - 260px)",
                stripe: ""
              },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "50" }
              }),
              _c("el-table-column", {
                attrs: { type: "index", label: "序号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "workDay", label: "日期" }
              }),
              _c("el-table-column", {
                attrs: { prop: "companyName", label: "线路" }
              }),
              _c("el-table-column", { attrs: { prop: "cph", label: "车辆" } }),
              _c("el-table-column", {
                attrs: { prop: "realityDriverName", label: "司机" }
              }),
              _c("el-table-column", {
                attrs: { prop: "moveType", label: "方向" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.moveType === 1 ? "上行" : "下行") +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "planLeaveTime", label: "计划发车" }
              }),
              _c("el-table-column", {
                attrs: { prop: "leaveTime", label: "实际发车" }
              }),
              _c("el-table-column", {
                attrs: { prop: "planArriveTime", label: "计划到达" }
              }),
              _c("el-table-column", {
                attrs: { prop: "arriveTime", label: "实际到达" }
              }),
              _c("el-table-column", {
                attrs: { prop: "drivingState", label: "行驶状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.drivingState === 0
                                ? "未确认"
                                : scope.row.drivingState === 1
                                ? "已完成"
                                : "未完成"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "runMile", label: "里程数" }
              }),
              _c("el-table-column", {
                attrs: { prop: "oilConsumption", label: "油耗" }
              }),
              _c("el-table-column", {
                attrs: { prop: "times", label: "趟次", width: "50" }
              }),
              _c("el-table-column", {
                attrs: { prop: "natureState", label: "变更说明" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm._f("filterNatureState")(scope.row.natureState)
                          )
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "备注" }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.form.currentPage,
                  "page-size": _vm.form.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  background: "",
                  total: _vm.total
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                  "update:currentPage": function($event) {
                    return _vm.$set(_vm.form, "currentPage", $event)
                  },
                  "update:current-page": function($event) {
                    return _vm.$set(_vm.form, "currentPage", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: "35%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "statementsForm",
                  staticClass: "demo-form dialog-form",
                  attrs: {
                    model: _vm.createStatementsFrom,
                    "label-position": "left",
                    "label-width": "120px",
                    rules: _vm.statementRules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "线路:", prop: "companyId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.createStatementsFrom.companyId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.createStatementsFrom,
                                "companyId",
                                $$v
                              )
                            },
                            expression: "createStatementsFrom.companyId"
                          }
                        },
                        _vm._l(_vm.busLineList, function(item) {
                          return _c("el-option", {
                            key: item.companyId,
                            attrs: {
                              label: item.companyName,
                              value: item.companyId
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "日期:", prop: "settlementTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          "value-format": "yyyy-MM-dd",
                          "picker-options": _vm.datePickerOptions
                        },
                        model: {
                          value: _vm.createStatementsFrom.settlementTime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.createStatementsFrom,
                              "settlementTime",
                              $$v
                            )
                          },
                          expression: "createStatementsFrom.settlementTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "buttonGroup text-right tabButtonGroup",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: { click: _vm.cancleStatementsFromDia }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.submitStatementsFrom }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dialogVisibletrip
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisibletrip,
                width: "45%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisibletrip = $event
                }
              }
            },
            [
              _c("addRecordTripDialog", {
                attrs: {
                  busLineList: _vm.busLineList,
                  drivingStateList: _vm.drivingStateOptions
                },
                on: {
                  closeDia: function(val) {
                    return (_vm.dialogVisibletrip = val)
                  },
                  submitTripDiaFrom: _vm.submitTripRecord
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.dialogVisibleSuretrip
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisibleSuretrip,
                width: "45%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisibleSuretrip = $event
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "form search-area",
                  staticStyle: { padding: "0 16px" }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "sureform",
                      staticClass: "demo-form form-search",
                      attrs: {
                        model: _vm.sureform,
                        rules: _vm.rules,
                        "status-icon": "",
                        "label-position": "left",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否完成:", prop: "drivingState" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sureform.drivingState,
                                callback: function($$v) {
                                  _vm.$set(_vm.sureform, "drivingState", $$v)
                                },
                                expression: "sureform.drivingState"
                              }
                            },
                            _vm._l(_vm.drivingStateOptions, function(item) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.value,
                                  attrs: { label: item.value }
                                },
                                [_vm._v(_vm._s(item.content))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "司机:" } },
                        [
                          _c(
                            "el-select",
                            {
                              ref: "select_element",
                              attrs: {
                                clearable: "",
                                filterable: "",
                                "filter-method": _vm.vehicleMethodFun,
                                placeholder: "请输入司机"
                              },
                              model: {
                                value: _vm.sureform.realityDriver,
                                callback: function($$v) {
                                  _vm.$set(_vm.sureform, "realityDriver", $$v)
                                },
                                expression: "sureform.realityDriver"
                              }
                            },
                            _vm._l(_vm.driversOptions, function(item) {
                              return _c("el-option", {
                                key: item.credentialsNo,
                                attrs: {
                                  label: item.driverName,
                                  value: item.credentialsNo
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm.sureform.recordIds.length === 1 &&
                      _vm.sureform.drivingState === 1
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "里程数:", prop: "runMile" } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0, size: "small" },
                                model: {
                                  value: _vm.sureform.runMile,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sureform, "runMile", $$v)
                                  },
                                  expression: "sureform.runMile"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注:", prop: "remark" } },
                        [
                          _c("el-input", {
                            staticStyle: {
                              "margin-top": "10px",
                              width: "250px"
                            },
                            attrs: { type: "textarea", rows: 3 },
                            model: {
                              value: _vm.sureform.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.sureform, "remark", $$v)
                              },
                              expression: "sureform.remark"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttonGroup text-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: { click: _vm.cancleSchedule }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.confirmSchedule }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }